import React, { FC } from 'react';

import styled, { css } from 'styled-components';

import ExternalLink from '@components/atoms/ExternalLink/ExternalLink';

import { isCN } from '@utils/origin';

import { I18nTranslation } from '@shared/interfaces/i18n';
import { Target } from '@shared/enums/target';
import { withTranslation } from 'i18n';

type CopyrightProps = {
  className: string;
  t: I18nTranslation;
};

const Copyright: FC<CopyrightProps> = ({ className, t }) => {
  const copyright = isCN ? 'copyright-cn' : 'copyright';
  const year = new Date().getFullYear();

  return (
    <div className={className} data-cy="copyright-test">
      {t(copyright, { year })}
      {isCN && (
        <>
          <StyledLink href="https://beian.miit.gov.cn" target={Target.Blank}>
            {t('icp')}
          </StyledLink>
          &nbsp;
          <StyledLink
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010502006038"
            target={Target.Blank}
          >
            {t('icpFillingNumber')}
          </StyledLink>
        </>
      )}
    </div>
  );
};

//withTranslation calls i18n
const styledCopyright = styled(withTranslation('common')(Copyright))`
  ${props => css`
    background-color: ${props.theme.colors.cornflowerBlue};
    border-top: 1px solid ${props.theme.colors.white};
    color: ${props.theme.colors.white};
  `};
  line-height: 4;
  font-size: 0.75em;
  text-align: center;
  margin: 0 14%;
`;

const StyledLink = styled(ExternalLink)`
  ${props => css`
    color: ${props.theme.colors.turquoise};
  `};
`;

export default styledCopyright;
